@import ../../styles/helpers

.actions
    position: relative

.list
    display: flex

.list > div,
.favorite,
.button
    &:not(:last-child)
        margin-right: 16px
