@import '../../styles/helpers'

.section
  display: flex
  justify-content: center
  align-items: center
  margin-bottom: 80px

  .container
    max-width: 1200px
    width: 100%
    padding: 0 20px
    box-sizing: border-box

  .wrapper
    display: flex
    justify-content: space-between
    gap: 40px
    margin-bottom: 50px

    .formWrapper
      flex: 2
      display: flex
      flex-direction: column
      gap: 20px
      align-items: center 
      padding: 20px 
      form
          width: 100% 
          max-width: 500px 
          display: flex
          flex-direction: column
          gap: 20px
  

    .content
      flex: 1
      padding: 20px

  input, select, textarea
    width: 100%
    padding: 15px
    margin-bottom: 20px
    border-radius: 4px
    box-sizing: border-box
    &:focus
      border-color: grey

@media (max-width: 768px)
  .wrapper
    flex-direction: column

  .imageWrapper, .content
    width: 100%
