@import ../../../styles/helpers

.head
    margin-bottom: 92px
    text-align: center
    +t
        margin-bottom: 64px
    +m
        margin-bottom: 48px

.stage
    margin-bottom: 8px

.title
    margin-bottom: 32px
    +m
        font-size: 32px

.list
    display: flex
    margin: 0 -12px
    +t
        display: block

.item
    flex: 0 0 calc(33.333% - 24px)
    width: calc(33.333% - 24px)
    margin: 0 12px
    +t
        width: 100%
        margin: 0
    &:not(:last-child)
        +t
            margin-bottom: 24px