@import ../../styles/helpers

.section
    +m
        padding-top: 48px

.control
    +m
        display: none

.head
    display: flex
    align-items: flex-start
    margin-bottom: 64px
    +m
        display: block
        margin-bottom: 48px

.box
    flex-grow: 1
    padding-right: 40px
    +m
        margin-bottom: 32px
        padding-right: 0

.titleContainer
    display: flex
    align-items: center
    gap: 16px
    margin-bottom: 16px
    flex-wrap: wrap
    +m
        gap: 8px

.title
    margin: 0
    +m
        font-size: 32px

.alternanceBadge
    display: inline-flex
    align-items: center
    padding: 6px 12px
    background: $p1
    color: $n8
    font-size: 14px
    font-weight: 500
    border-radius: 8px
    white-space: nowrap
    +dark
        background: $p2
        color: $n1

.line
    display: flex
    align-items: center
    flex-wrap: wrap

.avatar
    flex-shrink: 0
    width: 24px
    height: 24px
    margin-right: 20px
    img
        width: 100%
        height: 100%
        border-radius: 50%
        object-fit: cover
    
.rating
    display: flex
    align-items: center
    margin-right: 20px
    svg
        margin-right: 8px
        fill: $s3
    
.number
    font-weight: 500

.reviews
    margin-left: 8px
    color: $n4

.options
    display: flex
    flex-wrap: wrap
    margin: -4px 0 6px -20px
    +t
        flex: 0 0 100%
        margin-top: 6px

.option
    display: flex
    align-items: center
    margin: 10px 0 0 20px
    color: $n4
    svg
        margin-right: 8px
        fill: $n4

.accessibility
    margin-top: 16px
    margin-bottom: 24px

.accessibilityBadge
    display: flex
    align-items: flex-start
    gap: 12px
    background: #F5F5F5
    border-radius: 12px
    padding: 16px
    border-left: 4px solid #3B71FE
    +dark
        background: $n2
        border-left-color: $p1

    svg
        flex-shrink: 0
        margin-top: 2px
        fill: #3B71FE
        +dark
            fill: $p1

.accessibilityContent
    display: flex
    flex-direction: column
    gap: 12px

.accessibilityText
    color: $n2
    font-size: 14px
    line-height: 1.4
    +dark
        color: $n8

.accessibilityCard
    display: flex
    align-items: flex-start
    gap: 12px
    background: #F4F5F6
    border-radius: 12px
    padding: 16px
    margin-top: 20px
    max-width: 800px

.accessibilityIcon
    flex-shrink: 0
    margin-top: 2px
    svg
        fill: #777E91
        +dark
            fill: $n8

.accessibilityContent
    p
        font-size: 14px
        line-height: 1.4
        color: #353945
        margin: 0
        +dark
            color: $n8

.actions
    flex-shrink: 0
