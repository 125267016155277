@import ../../../styles/helpers

.item
    display: block
    padding: 32px
    border-radius: 24px
    border: 1px solid $n7
    color: $n2
    transition: all .2s
    height: 100%
    &:hover
        border-color: $n6
    +dark
        background: $n2
        border-color: $n2
        color: $n8
        &:hover
            background: $n1

.badges
    display: flex
    flex-direction: column
    gap: 4px
    margin-bottom: 40px
    min-height: 36px

.counter, .counterAlternance
    margin-bottom: 0
    padding: 1px 8px
    font-size: 11.5px
    line-height: 16px
    border-radius: 10px
    display: inline-flex
    align-items: center
    justify-content: center
    max-width: fit-content

.counterAlternance
    background-color: #3772FF
    color: #FCFCFD

.icon
    display: flex
    align-items: center
    height: 32px
    margin-bottom: 24px
    svg
        fill: $n4
        +dark
            fill: $n8

.title
    margin-bottom: 4px
    +body-bold-2

.content
    +caption-2
    color: $n4