@import ../../../styles/helpers

.gallery
    +m
        margin: 0 -24px 0 -16px

.list
    display: grid

.tour
    margin-right: 8px
    grid-gap: 8px
    grid-template-columns: repeat(4,1fr)
    grid-template-rows: repeat(2,1fr)
    +m
        grid-template-columns: repeat(2,1fr)
        grid-template-rows: repeat(4,1fr)
    .preview
        &:first-child
            grid-column: 1 / 3
            grid-row: 1 / 3
            +m
                grid-column: 1 / 3
                grid-row: 1 / 3
        &:nth-child(2)
            grid-column: 3 / 4
            grid-row: 1 / 2
            +m
                grid-column: 1 / 2
                grid-row: 3 / 4
        &:nth-child(3)
            grid-column: 4 / 5
            grid-row: 1 / 2
            +m
                grid-column: 2 / 3
                grid-row: 3 / 4
        &:nth-child(4)
            grid-column: 3 / 4
            grid-row: 2 / 3
            +m
                grid-column: 1 / 2
                grid-row: 4 / 5
        &:nth-child(5)
            grid-column: 4 / 5
            grid-row: 2 / 3
            +m
                grid-column: 2 / 3
                grid-row: 4 / 5
    
.preview
    position: relative
    img
        width: 100%
        height: 100%
        border-radius: 16px
        object-fit: cover