@import ../../styles/helpers

.card
    display: flex
    flex-direction: column
    border-radius: 24px
    overflow: hidden
    background: $n8
    border: 1px solid $n6
    color: $n1
    transition: border-color .2s
    +dark
        background: $n1
        border-color: $n3
        color: $n8
    &.row
        flex-direction: row
        border-radius: 16px
        +x
            display: block
        .preview
            flex-shrink: 0
            width: 256px
            +x
                width: 100%
            &:before
                display: none
                +x
                    display: block
        .body
            display: flex
            flex-direction: column
            flex-grow: 1
        .line
            margin-bottom: 16px
        .price
            display: flex
            flex-direction: column-reverse
        .old
            margin: 6px 0 0
            text-decoration: none
        .options
            flex-wrap: nowrap
            +x
                flex-wrap: wrap
        .option
            &:nth-child(2)
                flex-shrink: 0
        .foot
            margin-top: auto


.preview
    position: relative
    height: 240px
    overflow: hidden
    &:before
        content: ""
        display: block
        padding-bottom: 68.6%
    img
        position: absolute
        top: 0
        left: 0
        width: 100%
        height: 100%
        object-fit: cover
        transition: transform 1s

.badge
    position: absolute
    top: 12px
    right: 12px
    background: $p1
    color: $n8
    padding: 6px 12px
    border-radius: 12px
    font-weight: 600
    font-size: 14px
    z-index: 1
    +dark
        background: $p2
        color: $n1


.body
    flex-grow: 1
    padding: 24px 22px

.line
    display: flex
    justify-content: space-between
    align-items: flex-start
    margin-bottom: 8px

.title
    +body-bold-2

.price
    margin-left: 16px
    padding: 6px 8px
    box-shadow: inset 0 0 0 2px $p4
    border-radius: 4px
    text-align: center
    +hairline-2
    color: $p4


.foot
    padding-top: 16px
    border-top: 1px solid $n6
    +dark
        border-color: $n2


.flex
    display: flex
    justify-content: space-between

.cost
    +caption-bold-2

.review
    +caption-2
    color: $n4