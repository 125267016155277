@import ../../../styles/helpers

.section
    +d
        margin-bottom: 50px
    +m
        margin-bottom: 50px

.container
    max-width: 1440px
    +w
        padding: 0 40px
    +t
        padding: 0
    +m
        padding: 0 8px

.preview
    position: relative
    &:before
        content: ""
        display: block
        padding-bottom: 60.63%
        +d
            padding-bottom: 64%
        +m
            display: none
    img
        position: absolute
        top: 0
        left: 0
        width: 100%
        height: 90%
        border-radius: 24px
        object-fit: cover
        +t
            border-radius: 0
        +m
            position: static
            height: 700px
            border-radius: 16px 16px 0 0
            object-position: 50% 100%

.slideImage
  width: 100%
  height: 100%
  object-fit: cover

.imageError
  width: 100%
  height: 100%
  background: rgba(0, 0, 0, 0.1)
  display: flex
  align-items: center
  justify-content: center
  min-height: 300px

.errorMessage
  color: $n2
  font-size: 18px
  text-align: center
  padding: 20px

.wrap
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    padding: 105px 80px 245px
    text-align: center
    +d
        padding: 64px 80px 200px
    +t
        padding: 80px 40px 180px
    +m
        display: block
        padding: 48px 32px
        text-align: center

.title
    margin-bottom: 16px
    +dark
        color: $n2

.info
    margin-bottom: 24px
    color: $n2
    +m
        font-size: 24px

.navigationContainer
  position: absolute
  bottom: 30px
  left: 0
  right: 0
  display: flex
  justify-content: center
  z-index: 3

.dots
  display: flex
  gap: 8px
  padding: 8px 16px
  background: rgba(255, 255, 255, 0.9)
  border-radius: 20px
  backdrop-filter: blur(10px)
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1)

.dot
  width: 8px
  height: 8px
  border-radius: 50%
  background: rgba(0, 0, 0, 0.2)
  border: none
  padding: 0
  cursor: pointer
  transition: all 0.3s ease

  &:hover
    background: rgba(0, 0, 0, 0.4)
    transform: scale(1.1)

  &.active
    background: rgba(0, 0, 0, 0.8)
    transform: scale(1.2)

  &:focus
    outline: none
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1)

@media (max-width: 767px)
  .navigationContainer
    bottom: 20px

.slideProgress
  position: absolute
  bottom: 80px
  right: 30px
  background: rgba(255, 255, 255, 0.9)
  padding: 12px 20px
  border-radius: 12px
  backdrop-filter: blur(10px)
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1)
  z-index: 3

.progressInfo
  display: flex
  align-items: center
  gap: 8px
  margin-bottom: 8px
  font-weight: 500
  color: $n7

  .currentSlide
    font-size: 20px
    color: $n8

  .separator
    color: $n4

  .totalSlides
    font-size: 16px
    color: $n5

.progressBar
  width: 100px
  height: 4px
  background: rgba(0, 0, 0, 0.1)
  border-radius: 2px
  overflow: hidden

.progressBarFill
  height: 100%
  background: $n8
  border-radius: 2px

@media (max-width: 767px)
  .slideProgress
    bottom: 100px
    right: 50%
    transform: translateX(50%)

.loading, .error
  text-align: center
  font-size: 18px
  color: $n2
  padding: 40px 0

.loading
  &:after
    content: "..."
    animation: dots 1.5s infinite

@keyframes dots
  0%, 20%
    content: "."
  40%
    content: ".."
  60%, 100%
    content: "..."
