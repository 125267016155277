.section 
  margin-bottom: 200px

.section2 .h2
  display: flex
  align-items: center


.wrapper
  display: flex
  align-items: center
  justify-content: center
  gap: 20px
  margin: auto
  max-width: 1200px
  margin-bottom: 100px

.content
  flex: 2
  padding: 20px

@media (max-width: 768px)
  .wrapper
    flex-direction: column
  .content
    width: 90%

    
.sectionPartners
  text-align: center
  padding: 50px 0

  .titleCenter
    font-size: 24px
    margin-bottom: 10px

  .subtitle
    margin-bottom: 20px

  .grid
    display: flex
    justify-content: center
    gap: 20px

  .partner img 
    max-width: 120px
    height: auto


