@import ../../../styles/helpers
    
.title
    margin: 0
    flex-shrink: 0
    +m
        margin-bottom: 32px

.list
    display: flex
    flex-wrap: wrap
    margin: -32px -16px 0
    +m
        display: block
        margin: 0

.card
    flex: 0 0 calc(33.333% - 32px)
    width: calc(33.333% - 32px)
    margin: 32px 16px 0
    +t
        flex: 0 0 calc(50% - 32px)
        width: calc(50% - 32px)
    +m
        width: 100%
        margin: 32px 0 0

.header
    display: flex
    align-items: center
    justify-content: space-between
    margin-bottom: 32px
    gap: 16px
    +m
        flex-direction: column
        align-items: flex-start
        gap: 16px

.filters
    display: flex
    gap: 16px
    align-items: center
    +m
        width: 100%
        flex-direction: column

.dropdown
    min-width: 200px
    +m
        width: 100%

.select
    min-width: 200px
    padding: 8px 16px
    border: 1px solid $n6
    border-radius: 12px
    background: $n8
    color: $n2
    font-size: 14px
    cursor: pointer
    transition: all .2s
    +dark
        background: $n1
        border-color: $n3
        color: $n8
    &:hover
        border-color: $p1
    &:focus
        outline: none
        border-color: $p1
    +m
        width: 100%

.btns
    margin-top: 48px
    text-align: center
    +m
        margin-top: 32px
    .button
        .loader
            margin: 0 16px 0 2px
            transform: scale(.8)

.info
    max-width: 482px
    margin: 0 auto 32px
    text-align: center
    +body-2
    color: $n4

.section
    padding: 64px 0
    background: $n8
    +dark
        background: $n1

.container
    max-width: 1200px
    margin: 0 auto
    padding: 0 32px

.header
    margin-bottom: 48px
    display: flex
    align-items: center
    justify-content: space-between
    +m
        display: block

.title
    margin-right: 32px
    +m
        margin: 0 0 32px

.filters
    display: flex
    margin: 0 -6px
    +m
        margin: 0 -4px
    .dropdown
        margin: 0 6px
        flex: 0 0 200px
        +m
            margin: 0 4px
            flex: 0 0 calc(33.333% - 8px)

.list
    margin: -32px -16px 0
    display: flex
    flex-wrap: wrap

.card
    flex: 0 0 calc(33.333% - 32px)
    margin: 32px 16px 0
    +t
        flex: 0 0 calc(50% - 32px)
    +m
        flex: 0 0 100%

.empty
    width: 100%
    text-align: center
    color: $n4
    font-size: 18px
    padding: 64px 0
    background: $n7
    border-radius: 12px
    margin-top: 32px
    +dark
        background: $n2
        color: $n6
        border: 1px solid $n3

.btns
    margin-top: 64px
    text-align: center

.button
    min-width: 204px
    +m
        width: 100%