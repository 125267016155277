@import ../../../styles/helpers

.section
    padding: 64px 0
    +m
        padding: 48px 0

.container
    position: relative
    z-index: 2

.inner
    background: #F8F9FA
    border-radius: 16px
    display: flex
    align-items: stretch
    overflow: hidden
    +dark
        background: $n2
        .image
            opacity: 0.9
    +t
        display: block

.left
    flex: 0 0 50%
    padding: 48px
    display: flex
    align-items: center
    justify-content: center
    +d
        padding: 32px
    +m
        padding: 24px

.wrap
    max-width: 424px
    +t
        max-width: 100%

.title
    margin-bottom: 20px
    font-size: 32px
    font-weight: 600
    +m
        font-size: 28px
        margin-bottom: 12px
    span
        margin-left: 8px
        font-size: 32px

.info
    margin-bottom: 32px
    color: $n4
    font-size: 15px
    line-height: 1.6
    +m
        margin-bottom: 24px

.right
    position: relative
    flex: 0 0 50%
    +dark
        &:after
            content: ""
            position: absolute
            top: 0
            left: 0
            right: 0
            bottom: 0
            background: rgba($n1, 0.1)
    img
        width: 100%
        height: 100%
        object-fit: cover
