.hero,
.h1,
.h2,
.h3,
.h4
    +dm-sans
    font-weight: 700

.hero
    font-size: 96px
    line-height: 1
    letter-spacing: -.02em
    +x
        font-size: 80px
    +d
        font-size: 64px

.h1
    font-size: 64px
    line-height: 1
    letter-spacing: -.02em

.h2
    font-size: 48px
    line-height: (56/48)
    letter-spacing: -.02em
    +m
        font-size: 40px
        line-height: (48/40)

.h3
    font-size: 40px
    line-height: (48/40)
    letter-spacing: -.01em

.h4
    font-size: 32px
    line-height: (40/32)
    letter-spacing: -.01em