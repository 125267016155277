@import ../../../styles/helpers

.section
    padding-bottom: 136px
    border-bottom: 1px solid $n6
    +d
        margin-bottom: 80px
        padding-bottom: 80px
    +m
        margin-bottom: 64px
        padding-bottom: 64px
    +dark
        border-color: $n2

.wrapper
    display: flex
    align-items: flex-start
    +t
        display: block

.details
    flex: 0 0 calc(100% - 448px)
    max-width: calc(100% - 448px)
    padding-right: 48px
    +d
        flex: 0 0 calc(100% - 400px)
        max-width: calc(100% - 400px)
        overflow: hidden 
    +t
        max-width: 100%
        margin-bottom: 48px
        padding: 0
        overflow: visible 

.receipt
    position: relative
    z-index: 3
    flex-shrink: 0
    +m
        margin: 0 -16px

.date
    +body-bold-2

.time
    +caption-2
    color: $n4

.price
    color: $p4
    transition: color .2s

.foot
    margin-top: 48px
    text-align: center

.more
    +button-1
    color: $n2
    transition: color .2s
    &:hover
        color: $p1
    +dark
        color: $n8
        &:hover
            color: $p1

            .title
    margin-bottom: 8px

.profile
    display: flex
    align-items: center
    margin-bottom: 32px
    span
        margin-right: 4px
        color: $n4

.avatar
    flex-shrink: 0
    width: 24px
    height: 24px
    margin: 0 8px
    img
        width: 100%
        height: 100%
        border-radius: 50%
        object-fit: cover

.name
    +body-bold-2

.options
    display: flex
    flex-wrap: wrap
    align-items: flex-start
    margin: -20px 0
    +d
        display: block
        margin: 0

.option
    display: flex
    align-items: center
    flex: 0 0 calc(50% - 40px)
    width: calc(50% - 40px)
    margin: 32px 20px 0
    +body-2
    color: $n4
    +d
        width: 100%
        margin: 0
    &:not(:last-child)
        margin-bottom: 16px

.icon
    display: flex
    justify-content: center
    align-items: center
    flex-shrink: 0
    width: 48px
    height: 48px
    margin-right: 20px
    border-radius: 50%
    border-width: 2px
    border-style: solid
    svg
        fill: $n2
        +dark
            fill: $n8

.box
    margin-top: 64px
    padding-top: 64px
    border-top: 1px solid $n6
    +d
        margin-top: 32px
        padding-top: 32px
    +dark
        border-color: $n3
    
.content
    margin-bottom: 32px
    +body-2
    h4
        margin-bottom: 32px
        +dm-sans
        font-size: 32px
        line-height: 40px
    p
        color: $n4
        &:not(:last-child)
            margin-bottom: 24px
        
.services
    margin-top: 32px



.wrapper
   
    margin: 0 -16px
    +m
        margin: 0 -8px

.slide
    height: 100%

.item
    height: 100%
    padding: 32px
    border-radius: 24px
    +d
        padding: 24px
    +dark
        color: $n2

.titleSkill
    padding-bottom: 20px
    font-weight: bold

.updateInfo
    margin-top: 32px
    padding-top: 24px
    border-top: 1px solid $n3
    +dark
        border-color: $n6
    p
        color: $n4
        font-size: 15px
        margin: 0
        +dark
            color: $n5
    .date
        color: $n7
        font-weight: 500
        +dark
            color: $n1