@import ../../../styles/helpers

.section
    +d
        margin-bottom: 50px
    +m
        margin-bottom: 50px

.container
    max-width: 1440px
    +w
        padding: 0 40px
    +t
        padding: 0
    +m
        padding: 0 8px

.preview
    position: relative
    &:before
        content: ""
        display: block
        padding-bottom: 60.63%
        +d
            padding-bottom: 64%
        +m
            display: none
    img
        position: absolute
        top: 0
        left: 0
        width: 100%
        height: 90%
        border-radius: 24px
        object-fit: cover
        +t
            border-radius: 0
        +m
            position: static
            height: 700px
            border-radius: 16px 16px 0 0
            object-position: 50% 100%
    
.wrap
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    padding: 105px 80px 245px
    text-align: center
    +d
        padding: 64px 80px 200px
    +t
        padding: 80px 40px 180px
    +m
        display: block
        padding: 48px 32px
        text-align: center

.title
    margin-bottom: 16px
    +dark
        color: $n2

.info
    margin-bottom: 24px
    color: $n2
    +m
        font-size: 24px
